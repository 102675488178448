import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import "./assets/css/common.css"

import './ga' // Google Analytics

Vue.config.productionTip = false

store.dispatch('sitecfg/initializeData').then(()=>{
  document.title = store.state.sitecfg.sitecfg.title
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
